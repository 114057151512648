import { useState, useEffect } from "react";
import Header from "../Header/Header";
import {
  Col,
  Row,
  DatePicker,
  InputNumber,
  Form,
  Select,
  Table,
  notification,
} from "antd";
import "./MealCalculatorStyle.css";
import { postApiWithoutAuth, getApiWithoutAuth } from "../utilis/api";
import moment from "moment";

const MealCalculator = () => {
  const [petType, setPetType] = useState([]);
  const [petData, setPetData] = useState({});
  const initialData = [
    { name: "Weight", value: "--" },
    { name: "Meals / day", value: "--" },
    { name: "Age", value: "--" },
    { name: "Daily feeding amount", value: "--" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "name",
      render: (text, record) => {
        return <span className="name-cell">{text}</span>;
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      editable: true,
      render: (text, record) => {
        return <span className="value-cell">{text}</span>;
      },
    },
  ];

  const handleFormValuesChange = (changedValues) => {
    const editedFieldName = Object.keys(changedValues)[0];
    if (editedFieldName === "date_of_birth") {
      const formattedDate =
        changedValues[editedFieldName]?.format("DD/MM/YYYY");
      setPetData({
        ...petData,
        [editedFieldName]: formattedDate,
      });
    } else {
      setPetData({
        ...petData,
        [editedFieldName]: changedValues[editedFieldName],
      });
    }
  };

  const [data, setData] = useState(initialData);

  const tableData = data.map((item, index) => ({ ...item, key: index }));

  const mealCalculation = async () => {
    const response = await postApiWithoutAuth("calculate", petData);

    if (!response.success) {
      notification.error({
        message: "Error",
        description: response.message?.data?.error,
        placement: "topLeft",
      });
      return;
    }
    const updatedData = initialData.map((item) => {
      const responseItem = response?.data.data[item.name];

      if (responseItem !== undefined) {
        return { ...item, value: responseItem };
      }
      return item;
    });
    if (
      response?.data.data.meal_divisions &&
      Array.isArray(response.data.data.meal_divisions)
    ) {
      response.data.data.meal_divisions.forEach((division) => {
        const divisionName = division.label;
        const divisionQuantity = division.quantity;

        const divisionRow = {
          name: divisionName,
          value: divisionQuantity,
        };
        updatedData.push(divisionRow);
      });
    }

    setData(updatedData);
  };
  const onFinish = () => {
    mealCalculation();
  };
  const getPetType = async () => {
    const response = await getApiWithoutAuth("pets");
    if (!response.success) {
      return;
    }
    setPetType(response?.data?.data);
  };

  useEffect(() => {
    getPetType();
  }, []);

  return (
    <>
      <Header />
      <div style={{ marginTop: "130px" }}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          className="rowStyling"
        >
          <Col xs={24} sm={12}>
            <div className="inputFieldsContainer">
              <Form
                name="petForm"
                onFinish={onFinish}
                initialValues={{
                  petType: "",
                  dob: moment(),
                  weight: 0,
                }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="formInputFieldStyle"
                onValuesChange={handleFormValuesChange}
              >
                <Form.Item
                  label={
                    <span className="form-label">
                      What food are you feeding?
                    </span>
                  }
                  name="pet_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select the food type",
                    },
                  ]}
                  className="form-item-style"
                >
                  <Select placeholder="Select" className="formInputStyle">
                    {petType?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={
                    <span className="form-label">
                      What is the date of birth of your pet (dd/mm/yyyy)?
                    </span>
                  }
                  name="date_of_birth"
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: "Please select the date of birth",
                    },
                  ]}
                  className="form-item-style"
                >
                  <DatePicker className="formInputStyle" format="DD/MM/YYYY" />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="form-label">
                      What is the weight of your pet in kg?
                    </span>
                  }
                  name="weight"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the weight",
                    },
                  ]}
                  className="form-item-style"
                >
                  <InputNumber
                    type="number"
                    placeholder="Enter weight"
                    min={0}
                    className="formInputStyle"
                    style={{ height: "60px" }}
                  />
                </Form.Item>

                <Form.Item>
                  <button type="submit" className="CalculateButton">
                    Calculate
                  </button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className="tableContainer">
              <div className="tableStyle">
                <Table
                  showHeader={false}
                  dataSource={tableData}
                  columns={columns.map((col) => ({
                    ...col,
                    onCell: (record) => ({
                      record,
                      editable: col.editable,
                      dataIndex: col.dataIndex,
                      title: col.title,
                    }),
                  }))}
                  pagination={false}
                  className="custom-table"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MealCalculator;
