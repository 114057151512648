import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
const baseInstance = axios.create({
  baseURL: "https://api-calculator.australianpetnutrition.com.au/api/",
});

export const postApiWithoutAuth = async (url, body) => {
  try {
    const response = await baseInstance.post(url, body);
    return {
      data: response.data,
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response,
    };
  }
};
export const getApiWithoutAuth = async (url) => {
  try {
    const response = await baseInstance.get(url);
    return {
      data: response.data,
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response,
    };
  }
};
