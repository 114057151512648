import logo from "./logo.svg";
import MealCalculator from "./component/MealCalculator/MealCalculator";
import "./App.css";

function App() {
  return (
    <div className="App">
      <MealCalculator />
    </div>
  );
}

export default App;
