import "./HeaderStyle.css";
import thumb_Logo from "../../component/assests/images/thumb_Logo.png";
const Header = () => {
  return (
    <>
      <div className="headerStyling">
        <div>
          <img src={thumb_Logo} alt="petImage" className="headerImageStyle" />
        </div>
        <div className="headerText">Pet Meal Calculator</div>
        {/* <div>
          <img
            src={petImageTwo}
            alt="petImage"
            className="headerImageSecondStyle"
          />
        </div> */}
      </div>
    </>
  );
};
export default Header;
